.level {
  text-align: left;
}

.points {
  text-align: left;
  margin-bottom: 1em;
}

.overdue {
  margin-top: 1em;
}
