.board {
  display: inline-grid;
  justify-items: stretch;
  align-items: stretch;
  justify-content: stretch;
  align-content: stretch;
  grid-gap: 1vmin 1vmin;
}

.computer {
  color: black;
  display: inline-block;
  height: 1.5em;
  width: 1.5em;
  background-size: contain;
  background-repeat: no-repeat;
}

.computer.good {
  background-image: url(assets/computer.gif);
}

.computer.bad {
  background-image: url(assets/computer.gif);
  animation: blink-bad-computer 0.5s infinite;
}

.computer.overdue {
  background-image: url(assets/filip.gif);
}

@keyframes blink-bad-computer {
  0%, 49% {
    background-color: orangered;
  }
  50%, 100% {
    background-color: transparent;
  }
}
