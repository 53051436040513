.button {
  color: white;
  background: #6b7878;
  font-family: 'VT323', monospace;
  font-size: calc(12px + 3vmin);
  padding: 0.2em;
  margin: 0.5em 0;
  display: block;
  width: 100%;
  border: 2px solid #ffffff;
}
