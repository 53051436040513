.root {
  text-align: center;
  font-family: 'VT323', monospace;
}

.container {
  background-color: #1f2525;
  padding: 0 1em;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(12px + 3vmin);
  color: white;
}

.gift {
  max-width: 50vmin;
}
